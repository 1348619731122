import { string } from 'prop-types'

export default function IconSocialLinkedin({ color, height = '48px', width = '48px' }) {
  return (
    <svg height={height} viewBox="0 0 48 48" width={width}>
      <g fill="none" fillRule="evenodd" id="Icons" stroke="none" strokeWidth="1">
        <g fill={color} transform="translate(-702.000000, -265.000000)">
          <path
            d="M746,305 L736.2754,305 L736.2754,290.9384 C736.2754,287.257796 734.754233,284.74515 731.409219,284.74515 C728.850659,284.74515 727.427799,286.480788 726.765522,288.074854 C726.517168,288.661395 726.555974,289.478453 726.555974,290.295511 L726.555974,305 L716.921919,305 C716.921919,305 717.046096,280.091247 716.921919,277.827047 L726.555974,277.827047 L726.555974,282.091631 C727.125118,280.226996 730.203669,277.565794 735.116416,277.565794 C741.21143,277.565794 746,281.474355 746,289.890824 L746,305 L746,305 Z M707.17921,274.428187 L707.117121,274.428187 C704.0127,274.428187 702,272.350964 702,269.717936 C702,267.033681 704.072201,265 707.238711,265 C710.402634,265 712.348071,267.028559 712.41016,269.710252 C712.41016,272.34328 710.402634,274.428187 707.17921,274.428187 L707.17921,274.428187 L707.17921,274.428187 Z M703.109831,277.827047 L711.685795,277.827047 L711.685795,305 L703.109831,305 L703.109831,277.827047 L703.109831,277.827047 Z"
            id="LinkedIn"
          />
        </g>
      </g>
    </svg>
  )
}

IconSocialLinkedin.propTypes = {
  color: string.isRequired,
  height: string,
  width: string,
}
