import { string } from 'prop-types'

export default function IconSubmit({ color = 'transparent' }) {
  return (
    <svg height="15" viewBox="0 0 18 15" width="18">
      <path
        d="M9 0C6.83805 0 4.96244 1.24248 3.97194 3.06438C1.70452 3.4361 0 5.48963 0 7.92857C0 10.479 1.8694 12.61 4.28607 12.8371C4.52172 12.8593 4.71429 12.6653 4.71429 12.4286C4.71429 12.1919 4.5216 12.0026 4.28653 11.975C2.37473 11.7504 0.857143 10.0391 0.857143 7.92857C0.857143 5.8145 2.37896 4.09867 4.29754 3.8845L4.53 3.85855L4.63393 3.64901C5.46112 1.9814 7.11305 0.857143 9 0.857143C11.3755 0.857143 13.3776 2.63424 13.8288 5.03764L13.8897 5.36208L14.2189 5.38601C15.8389 5.50378 17.1429 6.90982 17.1429 8.67857C17.1429 10.5318 15.7153 12 14 12H13.7143C13.4776 12 13.2857 12.1919 13.2857 12.4286C13.2857 12.6653 13.4776 12.8571 13.7143 12.8571H14C16.2276 12.8571 18 10.9653 18 8.67857C18 6.60154 16.5403 4.87248 14.6046 4.56822C13.9744 1.95161 11.7178 0 9 0Z"
        fill={color}
      />
      <path
        d="M5.7078 8.8668L8.67078 5.71642C8.8526 5.5231 9.1474 5.5231 9.32922 5.71642L12.2922 8.8668C12.474 9.06012 12.474 9.37356 12.2922 9.56689C12.1104 9.76021 11.8156 9.76021 11.6338 9.56689L9.46559 7.26158V13.6773C9.46559 13.9344 9.25714 14.1429 9 14.1429C8.74286 14.1429 8.53441 13.9344 8.53441 13.6773V7.26158L6.36624 9.56689C6.18441 9.76021 5.88962 9.76021 5.7078 9.56689C5.52597 9.37356 5.52597 9.06012 5.7078 8.8668Z"
        fill={color}
      />
    </svg>
  )
}

IconSubmit.propTypes = {
  color: string,
}
