// locale is used for catalogs
// language is used for translations
import getLanguage from '../common/utilities/getLanguage.js'
import loadLanguage from '../common/utilities/loadLanguage.js'
import convertToCorrectLocale from '../common/utilities/convertToCorrectLocale.js'

const ACCEPTED_LOCALE_REGEX = /^[a-z]{2}(-|_)[a-z]{2}/

/**
 * Originally in Flowbox we were validating the locale to be either a valid one (e.g. en-gb, en-us, etc)
 * or a language (e.g. en, fr, etc). If it was a language, we would convert it to a locale.
 * Because of the migration from Photoslurp and due to the fact that in Photoslurp we would accept any
 * kind of locale, we decided to remove the locale validation and pass it to the Backend in the same
 * format we receive it. If no locale specified we won't pass it to the Backend and the Backend itself
 * will automatically go to the primary catalog.
 */
class Locale {
  constructor(locale) {
    let parsedLocale
    if (locale) {
      // legacy code
      if (locale.toLowerCase() === 'auto') {
        parsedLocale = window.navigator.language.toLocaleLowerCase()
      } else {
        parsedLocale = locale.toLowerCase()
      }
    }

    this.locale = parsedLocale || undefined
    this.language = getLanguage(parsedLocale)
    this.translations = {
      postAltText: () => {}, // this is a noop needed for Setup and Styling due to promise not resolved from loadLanguage
    }

    this.loadLanguage()
  }

  loadLanguage() {
    return loadLanguage(this.language).then((module) => {
      this.translations = module.default
    })
  }

  /**
   * Return a valid locale. Needed for localization purposes.
   */
  get validLocale() {
    if (this.locale && this.locale.match(ACCEPTED_LOCALE_REGEX)) {
      return this.locale.replace('_', '-').substring(0, 5)
    }
    const convertedLocale = convertToCorrectLocale(this.language)
    return convertedLocale || this.language
  }

  getTranslation(key) {
    return this.translations[key]
  }

  setLocaleBasedOnProductlist(productListLocale) {
    if (this.locale === undefined && productListLocale) {
      this.language = getLanguage(productListLocale)
      this.locale = productListLocale
      this.loadLanguage()
    }
  }

  get isRTL() {
    return ['ar'].includes(this.language)
  }

  toString() {
    return `New Locale: ${this.locale}. Language: ${this.language} `
  }
}

export default Locale
