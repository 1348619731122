import { useState, createContext, useContext } from 'react'
import PropTypes from 'prop-types'

import sendTrackingEvent from '../../utilities/sendTrackingEvent.js'

async function addClickToProduct(e, productId, url, payload, settings) {
  const { allowCookies, enableWebAnalytics } = settings

  e.preventDefault()

  sendTrackingEvent({
    action: 'click',
    allowCookies,
    enableWebAnalytics,
    ctaType: 'product_cta',
    eventName: 'cta_clicked',
    eventLabel: 'cta',
    eventAction: 'clicked',
    interactive: true,
    flowKey: payload.feedKey,
    postId: payload.postKey,
    productKey: payload.productKey,
    target: 'cta',
  })

  window.location.assign(url)
}

const SetProductsContext = createContext(undefined)

export const useHotspots = () => {
  const context = useContext(SetProductsContext)
  if (context === undefined) {
    throw new Error('useHotspots must be used within HotspotsProvider')
  }
  return context
}

const HotspotsProvider = ({ children }) => {
  const [activeProductId, setActiveProductId] = useState('')
  const [currentProducts, setCurrentProducts] = useState([])
  const [hasSentEvent, setHasSentEvent] = useState(false)
  const [containerSize, setContainerSize] = useState([0, 0])
  const [settings, setSettings] = useState({})
  const [postKey, setPostKey] = useState('')
  const [feedKey, setFeedKey] = useState('')
  const [hotspots, setHotspots] = useState([])

  const activeProduct = currentProducts.find((product) => product.key === activeProductId)

  const activeProductName = activeProduct?.details?.name

  const getAnalyticsPayload = (index) => ({
    feedKey,
    productIndex: index,
    postKey,
    productKey: activeProduct.key,
  })

  const fetchHotspots = async (post) => {
    const isHotspotsEnabled = post.hotspots_assigned

    const url = `${__EXPERIENCE_ENDPOINT__}/hotspots/${post.key}?feedKey=${post.feedKey}`
    if (isHotspotsEnabled) {
      try {
        const response = await fetch(url)
        const data = await response.json()
        setHotspots(data.hotspots)
      } catch (e) {
        setHotspots([])
      }
    } else {
      setHotspots([])
    }
  }

  const handleOnClickProduct = (e, index) => {
    if (hasSentEvent) {
      return
    }
    const url = activeProduct?.details?.url || '#'
    setHasSentEvent(true)
    const payload = getAnalyticsPayload(feedKey, index, postKey, activeProduct)
    addClickToProduct(e, activeProduct.product_id, url, payload, settings)
  }

  return (
    <SetProductsContext.Provider
      value={{
        activeProductId,
        setActiveProductId,
        setCurrentProducts,
        currentProducts,
        activeProductName,
        handleOnClickProduct,
        setSettings,
        setPostKey,
        setFeedKey,
        fetchHotspots,
        hotspots,
        setContainerSize,
        containerSize,
      }}
    >
      {children}
    </SetProductsContext.Provider>
  )
}

HotspotsProvider.propTypes = {
  children: PropTypes.node,
}

export default HotspotsProvider
