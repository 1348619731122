const DEFAULT_EMBED_LANGUAGE = 'en'
const VALID_LANGUAGE_REGEX = /^[a-z]{2}$/

/**
 * We are accepting any kind of locale, so we can't be sure
 * we will be able to detect a valid language.
 * Some of the more popular formats we will receive are:
 * - en
 * - en-US
 * - en_US_1 (Magento)
 */
function getLanguage(locale) {
  // Locale === 'auto' is a legacy behaviour
  if (!locale || locale === 'auto') {
    return DEFAULT_EMBED_LANGUAGE
  }

  // If locale is a language, return it
  if (locale.match(VALID_LANGUAGE_REGEX)) {
    return locale
  }

  // If locale contains dashes, return the first part
  if (locale.indexOf('-') !== -1) {
    return locale.split('-')[0]
  }

  // If locale contains underscores, return the first part
  if (locale.indexOf('_') !== -1) {
    return locale.split('_')[0]
  }

  // Returns the first two characters or default to 'en'
  const language = locale.slice(0, 2)
  return language.length === 2 ? language : DEFAULT_EMBED_LANGUAGE
}

export default getLanguage
