import { string } from 'prop-types'

export default function IconSocialRss({ color, height = '48px', width = '48px' }) {
  return (
    <svg height={height} version="1.1" viewBox="0 0 44 44" width={width}>
      <g fill="none" fillRule="evenodd" id="Icons" stroke="none" strokeWidth="1">
        <g fill={color} id="Black" transform="translate(-800.000000, -760.000000)">
          <path
            d="M800.000471,797.714286 C800.000471,794.243 802.81487,791.428571 806.286118,791.428571 C809.757367,791.428571 812.571765,794.243 812.571765,797.714286 C812.571765,801.185571 809.757367,804 806.286118,804 C802.81487,804 800.000471,801.185571 800.000471,797.714286 Z M844,804 L835.619661,804 C835.619661,784.358714 819.641547,768.380429 800.000471,768.380429 L800.000471,760 C824.261497,760 844,779.738714 844,804 Z M829.333543,804 L820.953204,804 C820.953204,792.446857 811.553019,783.048143 800,783.048143 L800,774.666143 C816.174541,774.666143 829.333543,787.825286 829.333543,804 Z"
            id="RSS"
          />
        </g>
      </g>
    </svg>
  )
}

IconSocialRss.propTypes = {
  color: string.isRequired,
  height: string,
  width: string,
}
