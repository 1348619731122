import { func, string } from 'prop-types'

import Icon from '../../icon/index.js'
import { addSingleQueryParam, addQueryParams } from '../../../utilities/queryParams.js'
import '../../../../styles/components/_mediaUploaderIframe.scss'

function MediaUploaderIframe({ feedKey, handleCloseClick, language, termsAndConditionsURL }) {
  let url = __MEDIA_UPLOADER_IFRAME__
  if (feedKey && language) {
    const params = {
      setLng: language,
      feed_key: feedKey,
    }
    url = addQueryParams(url, params)
  }
  if (termsAndConditionsURL) {
    url = addSingleQueryParam(url, 'toc', termsAndConditionsURL)
  }

  return (
    <div data-testid="media-uploader-iframe">
      <div className="bzfy-o-popup-overlay bzfy-o-media-uploader-iframe-container" />
      <div className="bzfy-o-popup bzfy-o-media-uploader-iframe-container">
        <button
          className="bzfy-o-media-uploader-iframe-popup-close"
          onClick={handleCloseClick}
          type="button"
        >
          <Icon color="#000" icon="close" />
        </button>
        <div className="bzfy-o-media-uploader-iframe-iframe">
          <iframe
            className="bzfy-o-media-uploader-iframe-browser"
            src={url}
            title="media-uploader-iframe"
          />
        </div>
      </div>
    </div>
  )
}

MediaUploaderIframe.propTypes = {
  feedKey: string.isRequired,
  handleCloseClick: func.isRequired,
  language: string.isRequired,
  termsAndConditionsURL: string,
}

export default MediaUploaderIframe
